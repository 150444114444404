import React from "react";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import StringBuilder from "../helper/StringBuilder";
import { Alarmanlage } from "./PriceCalculator/elements/Constants";
import Header from "../components/Header";
import { graphql } from "gatsby";
const Alarmanlagen = ({ city, data }) => {
    let citySlug = typeof city !== "undefined" ? city.slug : "";
    let stringBuilder = new StringBuilder(city);
    return (
        <div className="section alarmanlagen">
            {/* <Header
                page={data.site.siteMetadata.alamranlagenMetadata}
                siteMetadata={data.site.siteMetadata}
                contextData={{}}
            /> */}
            <h2 className="has-text-centered">
                Ihre Vorteile mit unseren Alarmanlagen
                {stringBuilder.getInStadt()}
            </h2>
            <ul className="checkmark centered-content">
                <li>
                    <span className="top">
                        Auf Ihre Bedürfnisse zugeschnitten
                    </span>
                    <p>
                        Sowohl Jablotron als auch Daitem sind modulare
                        Alarmanlagen-Systeme: Unsere Sicherheitsberater
                        {stringBuilder.getAusStadtUndUmgebung()} entwickeln
                        Sicherheitskonzepte, die zu 100% auf Ihre Bedürfnisse
                        maßgeschneidert sind.
                    </p>
                </li>
                <li>
                    <span className="top">
                        Keine Wartezeit bis zur Installation
                    </span>
                    <p>
                        Auf Wunsch kann Ihre neue Alarmanlage bereits wenige
                        Tage nach Auftragsvergabe installiert werden – damit Sie
                        nicht Monate auf die gewünschte Sicherheit warten
                        müssen.
                    </p>
                </li>
                <li>
                    <span className="top">Sparen mit der KfW</span>
                    <p>
                        Unsere Alarmanlagen entsprechen der Norm DIN EN 50131
                        (Grad 2) – Sie können von der KfW-Förderung profitieren
                        und bis zu 20% sparen. Wir helfen Ihnen dabei.
                    </p>
                </li>
                <li>
                    <span className="top">Polizei und Notrufleitstellen</span>
                    <p>
                        Unsere Alarmanlagen können auf eine
                        24/7-Notrufleitstelle aufgeschaltet werden. Auf Wunsch
                        kann auch die Polizei
                        {stringBuilder.getAusStadtUndUmgebung()} binnen Minuten
                        bei Ihnen nach dem Rechten schauen.
                    </p>
                </li>
                <li>
                    <span className="top">Testsieger</span>
                    <p>
                        Unsere Alarmanlagen sind allesamt preisgekrönt und
                        mehrfach von führenden Fachverbänden in der Kategorie
                        „Professionelle Alarmanlagen“ als Testsieger
                        ausgezeichnet worden.
                    </p>
                </li>
                <li>
                    <span className="top">Intuitiv bedienbar & smart</span>
                    <p>
                        Unsere Alarmanlagen können ganz einfach und u.a. auch
                        per Smartphone bedient und mit einer Vielzahl von
                        Smart-Home Komponenten erweitert werden.
                    </p>
                </li>
            </ul>
            <div className="has-text-centered">
                <PriceCalculationButton
                    text="mehr erfahren"
                    city={citySlug}
                    id="btn_vorteileallg_mehrerfahren"
                    product={Alarmanlage}
                />
                <br />
            </div>
        </div>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
                image
                image768
                image1024
                image1216
                image1408
                alarmanlagenMainMetadata {
                    title
                    keywords
                    description
                    ogTitle
                    ogDescription
                }
            }
        }
    }
`;
export default Alarmanlagen;
