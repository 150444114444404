import React from 'react';
import { Link } from 'gatsby';
import StringBuilder from '../helper/StringBuilder';
import QueryString from "../helper/QueryString";
import Fade from 'react-reveal/Fade';

export default class FaceToFace extends React.Component {
    render() {
        //redirection to /city/...
        let slug = typeof this.props.city !== 'undefined' ? ('/' + this.props.city.slug) : '';
        //redirection to /.. when special (partner, nebenan,...)
        let query = new QueryString();
        if (query.getSpecial()){
            slug = '';
        } 
        let stringBuilder = new StringBuilder(this.props.city);
        return (
            <div className="section face-to-face">
                <h2 className="title">Wir sichern Ihr Zuhause & Gewerbe{stringBuilder.getInStadt()}</h2>
                <div className="columns is-multiline">
                    <Fade left>
                        <div className="column left-column is-hidden-mobile">
                            <img src="/img/installation-alarmanlage-eigene-sicherheitstechniker.png" alt="Installation der Alarmanlage durch eigene Sicherheitstechniker"/>
                        </div>
                    </Fade>
                    <div className="column right-column">
                        <div className="box">
                            <Link to={slug + '/alarmanlagen/'}>
                                <article className="media">
                                    <div className="media-left">
                                        <figure className="image">
                                            <img src="/img/unsere-alarmanlagen-2.jpg" alt="Unsere Alarmanlagen" />
                                        </figure>
                                    </div>
                                        <div className="media-content">
                                            <p className="text top">Zu unseren</p>
                                            <p className="text middle">ALARMANLAGEN</p>
                                        </div>
                                </article>
                            </Link>
                        </div>

                        <div className="box">
                            <Link to={slug  + '/videoueberwachung/'}>
                                <article className="media">
                                    <div className="media-left">
                                        <figure className="image">
                                            <img src="/img/unsere-videoanlagen-2.jpg" alt="Unsere Videoanlagen"/>
                                        </figure>
                                    </div>
                                    <div className="media-content">
                                        <p className="text top">Zu unseren</p>
                                        <p className="text middle">VIDEOANLAGEN</p>
                                    </div>
                                </article>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="bottom is-hidden-mobile"/>
            </div>
        );
    }
}
