import React from "react";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import QueryString from "../helper/QueryString";
import StringBuilder from "../helper/StringBuilder";

class IntrusionAtlas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: "",
            plz: "",
        };
    }

    render() {
        let query = new QueryString();
        let city = this.props.city;
        let stringBuilder = new StringBuilder(city);
        let context =
            typeof this.props.context === "undefined" ? {} : this.props.context;
        let cityName = "";
        let slug = "";
        let image = "";
        let image768 = "";
        let image1024 = "";
        let image1216 = "";
        let image1408 = "";
        let disableBackground =
            typeof context.disableBackground === "undefined"
                ? false
                : context.disableBackground;
        let title = "Unser Einbruchsatlas für Deutschland";
        if (typeof city != "undefined") {
            cityName = city.name;
            slug = city.slug;
            title = `Unser Einbruchsatlas für ${stringBuilder.getStadtUndUmgebung()}`;
            image = "/img/hero/" + city.slug + "/" + city.image;
            image768 = "/img/hero/" + city.slug + "/" + city.image768;
            image1024 = "/img/hero/" + city.slug + "/" + city.image1024;
            image1216 = "/img/hero/" + city.slug + "/" + city.image1216;
            image1408 = "/img/hero/" + city.slug + "/" + city.image1408;
        } else {
            image = context.image;
            image768 = context.image768;
            image1024 = context.image1024;
            image1216 = context.image1216;
            image1408 = context.image1408;
        }
        const { result, plz } = this.state;
        return (
            <div
                className="section intrusion-atlas intrusion-atlashome "
                style={{ height: "30rem" }}
            >
                {disableBackground === false && (
                    <React.Fragment>
                        <div className="image">
                            <picture>
                                <source
                                    media="(max-width:768px)"
                                    srcSet={image768}
                                />
                                <source
                                    media="(max-width:1024px)"
                                    srcSet={image1024}
                                />
                                <source
                                    media="(max-width:1216px)"
                                    srcSet={image1216}
                                />
                                <source
                                    media="(min-width:1217px)"
                                    srcSet={image1408}
                                />
                                <img
                                    src={image}
                                    style={{ width: "auto" }}
                                    alt={title}
                                    title={title}
                                />
                            </picture>
                        </div>
                        <div className="white"></div>
                    </React.Fragment>
                )}
                <div className="content">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (e.target.checkValidity()) {
                                query.setValue("PLZ", plz);
                                fetch(
                                    process.env.GATSBY_API_URL +
                                        "/atlas/plz/" +
                                        plz,
                                    {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    }
                                )
                                    .then((response) => response.json())
                                    .then((response) => {
                                        this.setState({ result: response });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        }}
                    >
                        <div className="has-text-centered">
                            <h2 className="title">{title}</h2>
                        </div>
                        {!result && (
                            <React.Fragment>
                                <div className="has-text-centered text">
                                    Entdecken Sie mit unserem Einbruchsatlas das
                                    Risikopotenzial für Einbrüche in Ihrer
                                    unmittelbaren Nachbarschaft. Geben Sie
                                    einfach Ihre Postleitzahl ein – unser
                                    Algorithmus berechnet anhand offizieller
                                    Kriminalstatistiken die für Sie wichtigsten
                                    Informationen.
                                </div>
                                <div className="has-text-centered plz">
                                    <input
                                        type="text"
                                        placeholder="&#xf00e; Ihre Postleitzahl"
                                        required
                                        minLength="5"
                                        maxLength="5"
                                        className="input"
                                        onChange={(e) => {
                                            this.setState({
                                                plz: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="has-text-centered bottom">
                                    <button
                                        type="submit"
                                        id="btn_atlaslow_risikoprüfen"
                                        className="button preisrechner-btn preisrechner-btn-primary"
                                    >
                                        Risiko prüfen
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                        {result && (
                            <React.Fragment>
                                <div className="result has-text-centered">
                                    {result.Typ === "Stadt" ? "Die" : "Der"}{" "}
                                    {result.Typ}{" "}
                                    <strong>{result.StadtKreis}</strong> zählt
                                    laut polizeilicher Kriminalstatistik mit{" "}
                                    <strong>
                                        {result.pro100kEinwohnerExkl}
                                    </strong>{" "}
                                    Einbrüchen je 100.000 Einwohnern zu den{" "}
                                    <strong>
                                        {result.RankingBucket} kriminellsten
                                        Gegenden Deutschlands
                                    </strong>{" "}
                                    (von insgesamt 402).
                                    <br />
                                    Im Postleitzahlengebiet{" "}
                                    <strong>{result.PLZ}</strong> werden auch in
                                    diesem Monat voraussichtlich{" "}
                                    <strong>
                                        {result.proMonatExkl} neue Einbrüche
                                    </strong>{" "}
                                    registriert.
                                    <br />
                                    Wir empfehlen Ihnen eine professionelle{" "}
                                    <strong>Sicherheitsberatung</strong>, damit
                                    wir für Sie die Schwachstellen{" "}
                                    <strong>Ihrer Immobilie</strong> erkennen
                                    und ein Sicherheitskonzept erstellen –{" "}
                                    <strong>kostenlos</strong> und{" "}
                                    <strong>unverbindlich</strong>.
                                    <br />
                                    <strong> Fordern</strong> Sie jetzt eine
                                    <strong> individuelle</strong>{" "}
                                    Bestandsanalyse und{" "}
                                    <strong>Risikoeinschätzung</strong> an.
                                    Völlig <strong>kostenlos</strong>.
                                </div>
                                <div className="has-text-centered result">
                                    <PriceCalculationButton
                                        product="Intrusion"
                                        title="Jetzt loselegen"
                                        text="Jetzt Risikoeinschätzung anfordern"
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

export default IntrusionAtlas;
