import React from "react";
import { Link } from "gatsby";
import StringBuilder from "../helper/StringBuilder";

export default class OurTeam extends React.Component {
    render() {
        let stringBuilder = new StringBuilder(this.props.city);
        return (
            <div className="section our-team">
                <div className="body">
                    <div className="content-desktop only-desktop has-text-centered">
                        <div className="top-area">
                            <h2 className="top">
                                Das Sicherheits-Team{stringBuilder.getStadt()}{" "}
                                von MeinAlarm24
                            </h2>
                            <span className="middle">
                                Um das beste Sicherheitskonzept für Ihre
                                Immobilie{stringBuilder.getInStadt()} zu
                                ermitteln und dieses fachgerecht zu
                                installieren, setzt MeinAlarm24 auf Experten.
                                Unsere geschulten und zertifizierten
                                Sicherheitsberater & -techniker bringen
                                jahrzehntelange Erfahrungen im Bereich der
                                Sicherheitstechnik mit.
                            </span>
                            <p className="bottom">
                                <Link
                                    className="button preisrechner-btn preisrechner-btn-primary"
                                    to="/ueber-uns/"
                                    id="btn_ma24team_überuns"
                                >
                                    Über uns
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content-mobile only-mobile">
                    <h2 className="top has-text-centered">
                        Das Sicherheits-Team{stringBuilder.getStadt()} von
                        MeinAlarm24
                    </h2>
                    <span className="middle">
                        Um das beste Sicherheitskonzept für Ihre Immobilie zu
                        ermitteln und dieses fachgerecht zu installieren, setzt
                        MeinAlarm24 auf Experten. Unsere geschulten und
                        zertifizierten Sicherheitsberater & -techniker bringen
                        jahrzehntelange Erfahrungen im Bereich der
                        Sicherheitstechnik mit.
                    </span>
                    <p className="bottom has-text-centered">
                        <Link
                            className="button preisrechner-btn preisrechner-btn-primary"
                            to="/ueber-uns/"
                            id="btn_ma24team_überuns"
                        >
                            Über uns
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}
