import React from "react";
import { Link } from "gatsby";

export default class SevenDaysBanner extends React.Component {
    render() {
        let cssClasses =
            "section seven-days" +
            (typeof this.props.cssClass !== "undefined"
                ? " " + this.props.cssClass
                : "");
        let city =
            typeof this.props.city !== "undefined" ? this.props.city : {};
        let phone =
            typeof city.fon !== "undefined" ? city.fon : "0800 150 99 00";
        let phone_tec =
            "tel://" +
            (typeof city.fon_tec !== "undefined"
                ? city.fon_tec
                : "+498001509900");
        return (
            <div className={cssClasses}>
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column title">
                            Wir sind 7 Tage die Woche für Sie da.
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column"></div>
                        <div className="column parts">
                            <div className="columns part">
                                <div className="column part-image">
                                    <i className="fas fa-phone"></i>
                                </div>
                                <div className="column">
                                    <p className="title">
                                        <a
                                            id="btn_7days_phone"
                                            href={phone_tec}
                                        >
                                            {phone}
                                        </a>
                                    </p>
                                    <p className="sub-title">
                                        7 Tage die Woche.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="column parts">
                            <div className="columns part">
                                <div className="column part-image">
                                    <i className="fas fa-envelope"></i>
                                </div>
                                <div className="column">
                                    <p className="title">
                                        <a
                                            id="btn_7days_mail"
                                            href="mailto:beratung@meinalarm24.de"
                                        >
                                            beratung@meinalarm24.de
                                        </a>
                                    </p>
                                    <p className="sub-title">
                                        Für alle, die lieber schreiben.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="column parts">
                            <div className="columns part">
                                <div className="column part-image">
                                    <i className="fas fa-reply-all"></i>
                                </div>
                                <div className="column">
                                    <p className="title">
                                        <Link
                                            id="btn_7days_callback"
                                            to="/beratungstermine"
                                        >
                                            Rückruf-Service
                                        </Link>
                                    </p>
                                    <p className="sub-title">
                                        Wir rufen Sie gerne zurück.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="column"></div>
                    </div>
                    <div className="columns">
                        <div className="column subtitle">
                            Wir beraten Sie gerne in einem persönlichen Gespräch
                            am Telefon oder bei Ihnen vor Ort.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
